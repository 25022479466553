import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby-link';
import { parse } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import useLogout from '../hooks/use-logout';
import { confirmUpdateEmail } from '../apis/auth';
import Loader from '../components/common/loader';
import SEO from '../components/seo';
import EmailVerificationConfirmation from '../components/user/auth/email-verification-confirmation';
import {
  EMAIL_UPDATE_ALREADY_EXISTS,
  EMAIL_UPDATE_CONFIRMATION,
  EMAIL_UPDATE_INVALID_REQUEST,
  EMAIL_UPDATE_LINK_EXPIRED,
  LOG_IN_URL,
} from '../constants';
import PublicLayout from '../layout/public-layout';
import { resetErrorAction } from '../state/actions/auth';
import { IEmailUpdateConfirmation } from '../types/models/auth';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function ConfirmUpdateEmail({ location }: RouteComponentProps<{}>) {
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  const qs = location ? parse(location.search) : { linkId: '', userId: '', email: '' };

  const linkId: string = qs['linkId'] as string;
  const userId: string = qs['userId'] as string;
  const email: string = qs['email'] as string;

  const logout = useLogout();
  const dispatch = useDispatch();

  const { mutateAsync: updateUserEmail } = useMutation<{}, unknown, IEmailUpdateConfirmation>(
    ({ userId, linkId, email }) => confirmUpdateEmail({ userId, linkId, email })
  );

  useEffect(() => {
    const updateEmail = async () => {
      try {
        await updateUserEmail({ userId, linkId, email });
      } catch (e: any) {
        setErrorMessage(e?.message || '');
      } finally {
        setLoading(false);
      }
    };
    updateEmail();
  }, [email, linkId, updateUserEmail, userId]);

  useEffect(() => {
    if (!loading) {
      logout();
    }
  }, [loading, logout]);

  const handleSignIn = useCallback(() => {
    dispatch(resetErrorAction(LOG_IN_URL));
    navigate(LOG_IN_URL);
  }, [dispatch]);

  const renderPage = () => {
    if (loading) {
      return <Loader />;
    } else if (errorMessage && errorMessage === EMAIL_UPDATE_ALREADY_EXISTS.ERROR_MESSAGE) {
      return <EmailVerificationConfirmation headingText={EMAIL_UPDATE_ALREADY_EXISTS.DISPLAY_MESSAGE} />;
    } else if (errorMessage && errorMessage === EMAIL_UPDATE_LINK_EXPIRED.ERROR_MESSAGE) {
      return <EmailVerificationConfirmation headingText={EMAIL_UPDATE_LINK_EXPIRED.DISPLAY_MESSAGE} />;
    } else if (errorMessage) {
      return <EmailVerificationConfirmation headingText={EMAIL_UPDATE_INVALID_REQUEST.DISPLAY_MESSAGE} />;
    } else {
      return (
        <EmailVerificationConfirmation
          headingText={EMAIL_UPDATE_CONFIRMATION.DISPLAY_MESSAGE}
          paragraphText={`${EMAIL_UPDATE_CONFIRMATION.INSTRUCTION_TEXT} ${email}`}
          buttonText={EMAIL_UPDATE_CONFIRMATION.BUTTON_TEXT}
          onButtonClick={handleSignIn}
        />
      );
    }
  };

  return (
    <div>
      <SEO title="Confirm Update Email Address" />;
      <PublicLayout>
        <Container>{renderPage()}</Container>
      </PublicLayout>
    </div>
  );
}
